import React from "react"
import Styled from "styled-components"

import Dropzone from "./Dropzone"

const Title = Styled.span`
    color: ${({ theme }) => theme.white};
`
const Content = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
`
const Notes = Styled.span`
    margin-top: 5px;
    font-size: 18px;
    color: #484848;
`
const Upload = ({ title, notes, type, onFilesAdded, disabled }) => (
  <>
    <Content>
      <Title>{title}</Title>
      <Dropzone
        onFilesAdded={file => onFilesAdded(file, type)}
        disabled={disabled}
      />
      <Notes>{notes}</Notes>
    </Content>
  </>
)

export default Upload
