import Styled from "styled-components"

const TextArea = Styled.textarea`
    outline: none;
    resize: none;
    width: 100%;
    background-color: #1b1b1b;
    color: ${({ theme }) => theme.primary};
    border: 1px solid #484848;
    padding: 0 15px;
    border-radius: 0;
    font-size: 1em;
    text-transform: none;
    &:focus {
        border: ${({ theme }) => `1px solid ${theme.primary};`}
    }
    &::placeholder {
        color: #8e8e8e;
    }
`

export default TextArea
