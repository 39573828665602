import React from "react"
import Styled from "styled-components"
import { Controller } from "react-hook-form"
import Select from "./Select"

import { DAYS, MONTHS, YEARS } from "./Constants"

const DateContainer = Styled.div`
    display: flex;
    flex-direction: row;
    Select {
        width: 122px;
        text-align: center;
    }
`

const DateInput = ({ control }) => {
  return (
    <DateContainer>
      <Controller
        as={
          <Select>
            <option defaultValue>Day</option>
            {DAYS.map(day => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </Select>
        }
        control={control}
        name="cal_day"
        rules={{ required: "Please enter day" }}
      />
      <Controller
        as={
          <Select>
            <option defaultValue>Month</option>
            {MONTHS.map(month => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </Select>
        }
        control={control}
        name="cal_month"
        rules={{ required: "Please enter month" }}
      />
      <Controller
        as={
          <Select>
            <option defaultValue>Year</option>
            {YEARS.map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        }
        control={control}
        name="cal_year"
        rules={{ required: "Please enter year" }}
      />
    </DateContainer>
  )
}

export default DateInput
