import Styled from "styled-components"

const Input = Styled.input`
    outline: none;
    width: ${({ width }) => (width ? width : "368px")};
    background-color: #1b1b1b;
    color: ${({ theme }) => theme.primary};
    border: 1px solid #484848;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 1em;
    text-transform: none;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: #8e8e8e;
    }
`

export default Input
