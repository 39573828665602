import React from "react"
import Styled from "styled-components"

const Success = Styled.span`
    color: #3c763d;
    background-color: #dff0d8;
    margin-top: 1rem;
    text-align: center;
    padding: 0.75rem;
    border: 1px solid #d6e9c6;
    border-radius: 4px;
`

const MySuccess = ({ message }) => <Success>{message}</Success>

export default MySuccess
