import React, { useEffect, useState } from "react"
import Styled from "styled-components"

const Img = Styled.img`
    max-width: 100%;
    height: auto;
    margin: 15px;
`
const ImgName = Styled.h6`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    font-size: 18px;
    color: #484848;
`
const Remove = Styled.span`
    cursor: pointer;
    font-size: 24px;
    margin-left: 8px;
    color: ${({ theme }) => theme.primary};
    &:hover {
        opacity: 0.6;
    }
`

const UploadPreview = ({ files, onFileRemove }) => {
  const [previews, setPreviews] = useState([])
  useEffect(() => {
    let mounted = true
    if (mounted && files.length > 0) {
      let temp = []
      for (let i = 0; i < files.length; i++) {
        temp.push({
          file: URL.createObjectURL(files[i][0]),
          name: files[i][0].name,
        })
      }
      setPreviews(temp)
    }
    return () => {
      mounted = false
    }
  }, [files])
  const onPreviewRemove = index => {
    const temp = []
    for (let i = 0; i < files.length; i++) {
      if (i !== index) {
        temp.push(files[i])
      }
    }
    setPreviews(temp)
  }

  if (previews.length > 0) {
    return (
      <div className="container-fluid">
        <div className="row">
          {previews.map((preview, i) => (
            <div className="col-6 col-md-4 mx-auto" key={`img-preview-${i}`}>
              <Img src={preview.file} />
              <ImgName>
                {preview.name}
                <Remove
                  onClick={() => {
                    onFileRemove(i)
                    onPreviewRemove(i)
                  }}
                >
                  X
                </Remove>
              </ImgName>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return null
}

export default UploadPreview
