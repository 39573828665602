import React from "react"
import Styled from "styled-components"

const Error = Styled.span`
    color: #a94442;
    background-color: #f8d8da;
    margin-top: 1rem;
    text-align: center;
    padding: 0.75rem;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
`

const MyError = ({ message }) => <Error>{message}</Error>

export default MyError
