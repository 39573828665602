import React from "react"
import Styled from "styled-components"

import Input from "./Input"

const HeightContainer = Styled.div`
    display: flex;
    flex-direction: row;
    input {
        width: 184px;
    }
`

const HeightInput = ({ ref1, ref2 }) => {
  return (
    <HeightContainer>
      <Input
        type="tel"
        placeholder={`Height (feet)`}
        name={`height_ft`}
        ref={ref1}
      />

      <Input
        type="tel"
        placeholder={`Height (inches)`}
        name={`height_in`}
        ref={ref2}
      />
    </HeightContainer>
  )
}

export default HeightInput
