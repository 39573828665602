import React, { useState, useRef } from "react"
import Styled from "styled-components"

import { Cloud } from "../Fontawesome"

const DropzoneContainer = Styled.div`
    height: 200px;
    width: 200px;
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ highlight }) => (highlight ? "0.6" : "1.0")};
    background-color: transparent;
    border: 2px dashed #484848;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
    span {
        font-weight: bold;
        color: #484848; 
    }
    &:hover {
        border: ${({ theme }) => `2px dashed ${theme.white}`};
        span {
            color: ${({ theme }) => theme.white};
        }
    } 
`
const FileInput = Styled.input`
    display: none;
`
const Info = Styled.span`
    color: rgb(187, 186, 186);
`
const Dropzone = ({ disabled, onFilesAdded }) => {
  const [highlight, setHighlight] = useState(false)
  const fileInputRef = useRef()

  const openFileDialog = () => {
    if (disabled) return
    fileInputRef.current.click()
  }
  const handleOnFilesAdded = evt => {
    if (disabled) return
    const files = evt.target.files
    if (onFilesAdded) {
      const array = fileListToArray(files)
      onFilesAdded(array)
    }
  }
  const onDrop = evt => {
    evt.preventDefault()
    if (disabled) return
    const files = evt.dataTransfer.files
    if (onFilesAdded) {
      const array = fileListToArray(files)
      onFilesAdded(array)
    }
  }
  const fileListToArray = list => {
    const array = []
    for (let i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array
  }
  const onDragOver = evt => {
    evt.preventDefault()
    if (disabled) return
    setHighlight(true)
  }
  const onDragLeave = evt => {
    evt.preventDefault()
    setHighlight(false)
  }

  return (
    <DropzoneContainer
      disabled={disabled}
      highlight={highlight}
      onDragOver={evt => onDragOver(evt)}
      onDragLeave={evt => onDragLeave(evt)}
      onDrop={evt => onDrop(evt)}
      onClick={() => openFileDialog()}
    >
      <FileInput
        ref={fileInputRef}
        type="file"
        multiple
        onChange={evt => handleOnFilesAdded(evt)}
      />
      <Cloud width={100} color={"#d41f1f"} />
      <Info>{disabled ? "Ready" : "Click To Select"}</Info>
    </DropzoneContainer>
  )
}

export default Dropzone
