import React from "react"
import Styled from "styled-components"

const Button = Styled.button`
    display: inline-block;
    line-height: 30px;
    position: relative;
    text-align: center;
    background-color: ${({ theme }) => theme.primary};
    transform: skew(8deg);
    text-transform: uppercase;
    max-width: 100%;
    padding: 0 25px;
    margin-top: 20px;
    color: #fff;
    border: none;
    font-size: 17px;
    font-weight: 600;
    vertical-align: middle;
    cursor: pointer;
    transition: background-color .2s linear;
    border-radius: 3px;
    letter-spacing: 1.6px;
    font-family: 'Roboto', Helvetica, sans-serif;
    &:hover {
        transform: skew(0deg);
    }
`

const MyButton = ({ disabled, onClick, children }) => (
  <Button disabled={disabled} onClick={onClick}>
    {children}
  </Button>
)

export default MyButton
