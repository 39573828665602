import React from "react"
import Styled from "styled-components"
import { Controller } from "react-hook-form"

import Label from "./Label"
import Textarea from "./Textarea"
import Input from "./Input"
import Select from "./Select"
import DateInput from "./DateInput"
import HeightInput from "./HeightInput"
import Button from "./Button"
import Success from "./Success"
import Error from "./Error"
import Upload from "./Upload"
import UploadPreview from "./UploadPreview"

import { poundsToKg, heightToCm, isValidDate, getAge } from "../../utils"
import { BLOOD_TYPES } from "./Constants"

const Section = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
`

const RenderFormElement = (
  item,
  i,
  register,
  errors,
  onFilesAdded,
  onFileRemove,
  disabled,
  files,
  watchAll,
  control
) => {
  const {
    type,
    question,
    placeholder,
    required,
    name,
    fullwidth = false,
    id = null,
  } = item

  const renderAge = () => {
    const { cal_month, cal_day, cal_year } = watchAll
    if (
      cal_month &&
      cal_month !== "Month" &&
      cal_day &&
      cal_day !== "Day" &&
      cal_year &&
      cal_year !== "Year" &&
      isValidDate(`${cal_month}/${cal_day}/${cal_year}`)
    ) {
      const age = getAge(`${cal_month}/${cal_day}/${cal_year}`)
      return `(Age: ${age})`
    }
    return null
  }
  const renderAgeError = (day, month, year) => {
    if (day) {
      return <Error message={`Please enter day`} />
    }
    if (month) {
      return <Error message={"Please enter month"} />
    }
    if (year) {
      return <Error message={"Please enter year"} />
    }
  }
  const renderWeight = () => {
    const { weight } = watchAll
    if (weight) {
      const kg = poundsToKg(weight)
      return `(${kg})`
    }
  }
  const renderHeight = () => {
    const { height_ft, height_in } = watchAll
    if (height_ft && height_in) {
      const cm = heightToCm(height_ft, height_in)
      return `(${cm})`
    }
  }
  const renderHeightError = (err1, err2) => {
    if (err1) {
      return <Error message={err1.message} />
    }
    if (err2) {
      return <Error message={err2.message} />
    }
  }
  const renderBloodTypeError = err => {
    if (err) {
      return <Error message={err.message} />
    }
  }
  switch (type) {
    case "textarea":
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question}
          </Label>
          <Textarea
            placeholder={placeholder}
            name={name}
            ref={register({ required })}
            cols="40"
            rows="5"
            id={id}
          />
          {errors[name] && <Error message={errors[name].message} />}
        </Section>
      )
    case "text":
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question}
          </Label>
          <Input
            type="text"
            placeholder={placeholder}
            name={name}
            ref={register({ required })}
            id={id}
          />
          {errors[name] && <Error message={errors[name].message} />}
        </Section>
      )
    case "weight":
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question} {renderWeight()}
          </Label>
          <Input
            type="tel"
            placeholder={placeholder}
            name={name}
            ref={register({ required })}
            id={id}
          />
          {errors[name] && <Error message={errors[name].message} />}
        </Section>
      )
    case "height":
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question} {renderHeight()}
          </Label>
          <HeightInput
            error={errors}
            ref1={register({ required })}
            ref2={register({ required })}
          />
          {renderHeightError(errors.height_ft, errors.height_in)}
        </Section>
      )
    case "tel":
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question}
          </Label>
          <Input
            type="tel"
            placeholder={placeholder}
            name={name}
            ref={register({ required })}
            id={id}
          />
          {errors[name] && <Error message={errors[name].message} />}
        </Section>
      )
    case "blood_type":
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question}
          </Label>
          <Controller
            as={
              <Select>
                <option defaultValue>Blood Type</option>
                {BLOOD_TYPES.map(type => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Select>
            }
            control={control}
            name={name}
            rules={{ required }}
          />

          {renderBloodTypeError(errors.blood_type)}
        </Section>
      )
    case "date":
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question} {renderAge()}
          </Label>
          <DateInput Controlle={Controller} control={control} />
          {renderAgeError(errors.cal_day, errors.cal_month, errors.cal_year)}
        </Section>
      )
    case "file":
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question}
          </Label>
          <Upload
            title={"Image(s)"}
            notes={".png or .jpg file"}
            type={"img"}
            onFilesAdded={onFilesAdded}
            disabled={disabled}
          />
          {errors[name] && <Error message={errors[name].message} />}
          <UploadPreview files={files} onFileRemove={onFileRemove} />
        </Section>
      )
    default:
      return (
        <Section className={fullwidth ? "col-12" : "col-md-6"}>
          <Label>
            <span>{i + 1}.</span> {question}
          </Label>
          <Input
            type="text"
            placeholder={placeholder}
            name={name}
            ref={register({ required, maxLength: 80 })}
            id={id}
          />
          {errors[name] && <Error message={errors[name].message} />}
        </Section>
      )
  }
}

export {
  Select,
  Label,
  Textarea,
  Input,
  Upload,
  Button,
  Error,
  Success,
  RenderFormElement,
  Section,
}
