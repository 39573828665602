const currentYear = new Date().getFullYear()
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
export const YEARS = range(currentYear, currentYear - 75, -1)
export const MONTHS = range(1, 12, 1)
export const DAYS = range(1, 31, 1)

export const BLOOD_TYPES = [
  `Don't Know`,
  `O negative`,
  `O positive`,
  `A negative`,
  `A positive`,
  `B negative`,
  `B positive`,
  `AB negative`,
  `AB positive`,
]
